import React, { useState } from "react"
import DateTimePicker from 'react-datetime-picker';
import $ from 'jquery'

export const DatePicker=({type="datepicker", name="", id="", label="", isValidator, isRule, ...props})=>{
    const [isValue, setIsValue] = useState("");
    //modif css dengan jquery
    $('.react-datetime-picker__wrapper').addClass('form-control')
    $('.react-datetime-picker__wrapper').css({
        'z-index':0,
        'display':'flex'
    })
    $('.react-datetime-picker').css({
        'width':'100%',
    })
    return(
        <div className="input-group">
            <label forhtml={id?id:name}>{label}</label>
            <DateTimePicker
                onChange={(value)=>{setIsValue(value)}}
                format={type=="datetimepicker"?"dd/MM/yyyy h/mm/ss a":(type=="timepicker"?"h/mm/ss a":"dd/MM/yyyy")}
                disableClock
                value={isValue}
                {...props}
            />
            <input type="hidden" id={id?id:name} name={name} value={isValue} />
            {
            isValidator && isRule && (
                <>
                    <br/>
                    <span style={{color:'red', float:'inline-end'}}>{isValidator(name, isValue, isRule)}</span>
                </>
            )   
            }
        </div>
    )
}
