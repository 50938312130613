import { createStore, combineReducers } from 'redux';
import {
    IsQuestionnaire
} from './Reducer'
import { loadingBarReducer } from 'react-redux-loading-bar'

const MainReducer = combineReducers({
    loadingBar: loadingBarReducer,
    isQuestionnaire:IsQuestionnaire
});

export const store = createStore(MainReducer);