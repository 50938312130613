const request_data = {
    req_data:{},
    response_data:{}

}

export const IsQuestionnaire = (state = request_data, action) => {
    switch (action.type) {
        case 'GET_RESPONSE_INIT':
            return {
                ...state,
                req_data: action.data
            }
            break;
        case 'SAVE_RESPONSE':
            return {
                ...state,
                response_data: action.data
            }
            break;
        default:
            break;
    }
    return state;
}

const loading = {
    loading: false
}

export const IsLoading = (state = loading, action) => {
    switch (action.type) {
        case 'LOADING_TRUE':
            return {
                ...state,
                loading: true
            }
            break;
        case 'LOADING_FALSE':
            return {
                ...state,
                loading: false
            }
            break;
        default:
            break;
    }
    return state;
}