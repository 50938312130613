import React, { useState } from "react"

export const TextInput=({name="", id="", label="", isValidator, isRule, ...props})=>{
    const [isValue, setIsValue] = useState("");
    return(
        <div className="input-group">
            <label forhtml={id?id:name}>{label}</label>
            <input onChange={(event)=>{setIsValue(event.target.value)}} value={isValue} className="form-control" id={id?id:name} name={name} type="text" {...props} />
            {
            isValidator && isRule && (
                <>
                    <br/>
                    <span style={{color:'red', float:'inline-end'}}>{isValidator(name, isValue, isRule)}</span>
                </>
            )   
            }
        </div>
    )
}
