import React from 'react';
import {
        Route,
        Switch,
        } from 'react-router-dom'
import Home from '../components/Home'

const Navigation=()=>{
  return(
      <Switch>
          {/* dashboard */}
          <Route exact path="/:code?" component={Home}/>
      </Switch>
  );
}

export default Navigation;