import React, { useState } from "react"


export const TextArea=({name="", id="", label="", isValidator, isRule, ...props})=>{
    const [isValue, setIsValue] = useState("");
    return(
        <div class="input-group">
            <label forhtml={id?id:name}>{label}</label>
            <textarea onChange={(value)=>{setIsValue(value)}} id={id?id:name} class="form-control" rows={props.rows?props.rows:5} name={name} {...props}></textarea>
            {
            isValidator && isRule && (
                <>
                    <br/>
                    <span style={{color:'red', float:'inline-end'}}>{isValidator(name, isValue, isRule)}</span>
                </>
            )   
            }
        </div>
    )
}
