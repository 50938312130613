import React, { useState } from 'react';

export const ComboBox = ({name="", id="", label="", data=[], selected="", other=false, isValidator, isRule, ...props}) => {
    const [isSelected, setSelected] = useState(selected);
    const [isSelectedHelper, setSelectedHelper] = useState(selected);
    const [isShow, setIsShow] = useState(false);
    return(
            <div class="input-group">
                <label htmlfor={id?id:name}>{label}</label>
                <select onChange={(a)=>{setSelected(a.target.value==""?null:a.target.value); setIsShow(a.target.value=="others"?true:false)}} name={name} className="form-control" {...props}>
                    <option value={""} selected={false}>
                        Pilih Satu
                    </option>
                {
                data && data.map((value, index)=>{
                    return (<option selected={isSelected==value.value?true:false} value={value.value} key={name+'-'+index}>
                                {value.desc}
                            </option>)
                })
                }
                {
                    other && (
                        <option selected={isSelected=="others" || isShow?true:false} value={"others"}>
                            {other.desc}
                        </option>
                    )
                }
                </select>
                {
                    other && (
                        <div class="clear-fix" style={{marginTop:55, display:isShow?"block":"none"}}>
                            {
                                other.type=="text" && (
                                    <div className="input-group" style={{marginTop:5}}>
                                        <input onChange={(a)=>{setSelectedHelper(a.target.value)}} className="form-control" id={(id?id:name)+'-others'} name={name+"[others]"} type="text" value={isSelectedHelper} {...other.props} />
                                    </div>      
                                )
                            }
                            {
                                other.type=="textarea" && (
                                    <div className="input-group" style={{marginTop:5}}>
                                        <textarea onChange={(a)=>{setSelectedHelper(a.target.value)}} className="form-control" id={(id?id:name)+'-others'} name={name+"[others]"} {...other.props}>{isSelectedHelper}</textarea>
                                    </div>      
                                )
                            }
                            
                        </div>
                    )
                }
                {
                    isValidator && isRule && (
                        <>
                            <span style={{color:'red', float:'inline-end'}}>{isValidator(name, isSelected=="others"?(isSelectedHelper?isSelectedHelper:null):isSelected, isRule)}</span>
                        </>
                    )   
                }
            </div>
        )
}
