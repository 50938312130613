import React, { useState } from 'react';

const helper=(setState, isArray, value)=>{
    if(!isArray.includes(value)){
        setState([...isArray, value])
    }
    else{
        isArray.splice(isArray.indexOf(value), 1)
        setState([...isArray])
    }
}

const isShowHelper=(setState, isArray)=>{
    if(isArray.indexOf("others")!=-1){
        setState(true)
    }
    else{
        setState(false)
    }
}

export const CheckBoxButton = ({name="", id="", label="", data=[], checked=[], other=false, isValidator, isRule, ...props}) => {
    const [isChecked, setIsChecked] = useState(checked)
    const [isShow, setIsShow] = useState(false);
    const [isOtherHelper, setIsOtherHelper] = useState("");

    return(
        <div class="input-group">
            <label htmlfor={id?id:name}>{label}</label>
            {
            data && data.map((value, index)=>{
                return (<div class="clear-fix" key={(id?id:name)+'-'+index}>
                    <div class="pretty p-default p-thick p-pulse">
                        <input selected={isChecked.indexOf(value.value)!=-1?true:false} onClick={(event)=>{helper(setIsChecked, isChecked, event.target.value); isShowHelper(setIsShow, isChecked)}} value={value.value} id={(id?id:name)+'-'+index} name={name+"[]"} type="checkbox" {...props} />
                        <div class="state p-primary-o">
                            <label for={(id?id:name)+'-'+index}>{value.desc}</label>
                        </div>
                    </div>
                </div>)
            })
            }
            {
                other && (
                    <div class="clear-fix">
                        <div class="pretty p-default p-thick p-pulse">
                            <input selected={isChecked.indexOf("others")!=-1?true:false} onClick={(event)=>{helper(setIsChecked, isChecked, event.target.value); isShowHelper(setIsShow, isChecked)}} id={(id?id:name)+'-others'} value={"others"} name={name+"[]"} type="checkbox" {...props} />
                            <div class="state p-primary-o">
                                <label for={(id?id:name)+'-others'}>{other.desc}</label>
                            </div>
                        </div>
                        <div style={{display:isChecked.indexOf("others")!=-1 || isShow?"block":"none"}}>
                        {
                            other.type=="text" && (
                                <div className="input-group" style={{marginTop:5}}>
                                    <input onChange={(event)=>{setIsOtherHelper(event.target.value)}} className="form-control" id={(id?id:name)+'-others'} name={name+"[others]"} value={isOtherHelper} type="text" {...other.props} />
                                </div>      
                            )
                        }
                        {
                            other.type=="textarea" && (
                                <div className="input-group" style={{marginTop:5}}>
                                   <textarea onChange={(event)=>{setIsOtherHelper(event.target.value)}} className="form-control" id={(id?id:name)+'-others'} name={name+"[others]"} {...other.props}>{isOtherHelper}</textarea>
                                </div>      
                            )
                        }
                        </div>
                    </div>
                )
            }
            {
                isValidator && isRule && (
                    <>
                        <span style={{color:'red', float:'inline-end'}}>{isValidator(name, isChecked.indexOf("others")!=-1 && isChecked.length<=1?(isOtherHelper?isOtherHelper:null):isChecked, isRule)}</span>
                    </>
                )   
            }
        </div>
    )
}

