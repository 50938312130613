import axios from 'axios'
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL_API

export const IsApi=async(method='GET', url='', data)=>{
    // let token= await localStorage.getItem("auth")
    // axios.defaults.headers.common['Authorization']=`Bearer `+token
    //get
    if(method=='GET'){
      var response, status_response, status_server
      try {
        response = await axios.get(url)
        status_response=1
        status_server= response.status
      } catch (error) {
        if(error.response){
          status_server= error.response?error.response.status:null
          response= error.response
        }
        else{
          status_server= null
          response= error
        }
        status_response=0
      }
    }
    else if(method=='POST'){
      var response, status_response, status_server
      let isData= new URLSearchParams(data)
      try {
        response = await axios.post(url, isData, {
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
        })
        status_response=1
        status_server= response.status
      } catch (error) {
        if(error.response){
          status_server= error.response?error.response.status:null
          response= error.response
        }
        else{
          status_server= null
          response= error
        }
        status_response=0
      }
    }
    return {'status_response':status_server==204?0:status_response, 'status_server':status_server, 'response_origin':response}
}