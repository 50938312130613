import React from 'react'
import {RadioButton as RB} from './radioButton/Index'
import {CheckBoxButton as CB} from './checkButton/Index'
import {TextArea as TA} from './textArea/Index'
import {TextInput as TI} from './textInput/Index'
import {ComboBox as CBX} from './comboBox/Index'
import {DatePicker as DP} from './datePicker/Index'

export const RadioButton=({...props})=><RB {...props} />
export const CheckBoxButton=({...props})=><CB {...props} />
export const TextArea=({...props})=><TA {...props} />
export const TextInput=({...props})=><TI {...props} />
export const ComboBox=({...props})=><CBX {...props} />
export const DatePicker=({...props})=><DP {...props} />




