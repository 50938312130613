import React, {Component, useState} from 'react';
import { connect } from 'react-redux';
import { GetDataQuestionnaire, SaveDataQuestionnaire } from '../redux_handlers/action/ActionQuestionnaire';
import { Sleep } from '../redux_handlers/IsConfig';
import { RadioButton, CheckBoxButton, TextArea, TextInput, ComboBox, DatePicker} from '../boilerplates/aks/Index';
import ReactHtmlParser from 'react-html-parser';
import SimpleReactValidator from 'simple-react-validator';
var jwt = require('jsonwebtoken');
var moment = require('moment');


const IsRender=({data, onSubmit, validator, ...props})=>{
    const [isDisabled, setIsDisabled] = useState(false);

    let {loadAnimation, isRender, isNotif} = data
    return(
        <div style={{flex:1}}>
                <nav className="colorlib-nav" role="navigation">
                    <div className="top-menu">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div id="colorlib-logo" style={{color:'#eee'}}>{loadAnimation?ReactHtmlParser("..."):isRender?ReactHtmlParser("<img style='width:45px; hight:45px' src='"+isRender.origin.data.logos.value+"' /> "+isRender.origin.data.logos.sekolah):ReactHtmlParser("...")}</div>
                                </div>
                                <div className="col-md-6 text-right menu-1">
                                    <ul style={{color:'#eee'}}>
                                    {
                                        loadAnimation?ReactHtmlParser("<li>...</li>"):isRender?isRender.origin.data.navigations.map((value, index)=>{
                                            return (
                                                <li key={index} className={value.active?"active":""}><a href={value.link}>{value.name}</a></li>
                                            )
                                        }):ReactHtmlParser("<li className='active'><a href='#'>Home</a></li>")
                                    }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
		        </nav>
                <section id="home" className="video-hero" style={{height: 700, backgroundImage: "url("+"images/cover_img_1.jpg"+")",  backgroundSize:'cover', backgroundPosition: 'center', backgroundAttachment:'fixed'}} data-section="home">
                    <div className="overlay"></div>
                    <a className="player" data-property="{videoURL:'https://www.youtube.com/watch?v=vqqt5p0q-eU',containment:'#home', showControls:false, autoPlay:true, loop:true, mute:true, startAt:0, opacity:1, quality:'default'}"></a> 
                    <div className="display-t text-center">
                        <div className="display-tc">
                            <div className="container">
                                <div className="col-md-12 col-md-offset-0">
                                    <div className="animate-box">
                                        <h2>&nbsp;{loadAnimation?loadAnimation:(isRender? isRender.origin.data.title:'KUISIONER TIDAK ADA')}</h2>
                                        {/* {isRender && isRender.origin.data.desc} */}
                                        <p>{loadAnimation?'tunggu sebentar sedang memuat data':(isRender?isRender.origin.data.desc:'belum ada kuisioner...')}</p>
                                        {/* <p>{loadAnimation?'tunggu sebentar sedang memuat data':(isRender?isRender.origin.data.subtitle:'belum ada kuisioner periode ini')}</p> */}
                                        {
                                            isRender && (
                                            <p><a href={isRender.origin.data.buttons.link} className={isRender.origin.data.buttons.class}>{isRender.origin.data.buttons.text}</a></p>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="colorlib-featured" style={{padding:0}} style={isRender && isRender.origin.data.models?{display:'block'}:{display:'none'}} >
                    <div className="row animate-box">
                        <div className="featured-wrap">
                            <div className="col-md-8 col-md-offset-2">
                                <article>
                                    {
                                        isNotif && (
                                            <blockquote style={{color:'red'}}>
                                                {isNotif}
                                            </blockquote>
                                        )
                                    }
                                    {/* <blockquote>
                                        {isRender && isRender.origin.data.desc}
                                    </blockquote> */}
                                    <form onSubmit={(event)=>{onSubmit(event); setIsDisabled(true)}} id="isForm">
                                        <div className="panel panel-default" style={{borderColor: 'transparent', paddingBottom:15}} >                                                    
                                        {
                                            isRender && isRender.origin.data.models && isRender.origin.data.models.map((value, index)=>{
                                                return (<>
                                                    <div className="panel-heading" style={{backgroundColor:'#f5f5f5', marginTop:index!=0 && 35}}><strong>{value.title}</strong></div>
                                                        {
                                                            value.quistions && value.quistions.map((v, i)=>{
                                                                if(v.type=="text"){
                                                                    return(
                                                                        <>
                                                                            <div key={v.id+i}>
                                                                                <TextInput label={(i+1)+") "+v.label} id={v.id} name={v.name} isValidator={validator} isRule={v.add_infos && v.add_infos.validation?v.add_infos.validation:null} placeholder="put your answer here" />
                                                                            </div>
                                                                        </>
                                                                    )
                                                                }
                                                                else if(v.type=="textarea"){
                                                                    return(
                                                                        <>
                                                                            <div key={v.id+i}>
                                                                                <TextArea label={(i+1)+") "+v.label} id={v.id} name={v.name} isValidator={validator} isRule={v.add_infos && v.add_infos.validation?v.add_infos.validation:null} placeholder="put your answer here" />
                                                                            </div>
                                                                        </>
                                                                    )
                                                                }
                                                                else if(v.type=="radio"){
                                                                    let opt=[]
                                                                    v.add_infos && v.add_infos.opt.map((optv, opti)=>{
                                                                        opt.push({'key':v.name+opti, 'value':optv.value, 'desc':optv.desc})
                                                                    })
                                                                    return(
                                                                        <>
                                                                            <div key={v.id+i}>
                                                                                <RadioButton key={v.id+i} id={v.id} name={v.name} label={(i+1)+") "+v.label} data={opt} isValidator={validator} isRule={v.add_infos && v.add_infos.validation?v.add_infos.validation:null} checked={v.add_infos.checked} other={v.add_infos && v.add_infos.other ?v.add_infos.other:false} />
                                                                            </div>
                                                                        </>
                                                                    )
                                                                }
                                                                else if(v.type=="checkbox"){
                                                                    let opt=[]
                                                                    v.add_infos && v.add_infos.opt.map((optv, opti)=>{
                                                                        opt.push({'key':v.name+opti, 'value':optv.value, 'desc':optv.desc})
                                                                    })
                                                                    return(
                                                                        <>
                                                                            <div key={v.id+i}>
                                                                                <CheckBoxButton key={v.id+i} id={v.id} name={v.name} label={(i+1)+") "+v.label} data={opt} isValidator={validator} isRule={v.add_infos && v.add_infos.validation?v.add_infos.validation:null} checked={v.add_infos.checked} other={v.add_infos && v.add_infos.other ?v.add_infos.other:false} />
                                                                            </div>
                                                                        </>
                                                                    )
                                                                }
                                                                else if(v.type=="combobox"){
                                                                    let opt=[]
                                                                    v.add_infos && v.add_infos.opt.map((optv, opti)=>{
                                                                        opt.push({'key':v.name+opti, 'value':optv.value, 'desc':optv.desc})
                                                                    })
                                                                    return(
                                                                        <>
                                                                            <div key={v.id+i}>
                                                                                <ComboBox key={v.id+i} id={v.id} name={v.name} label={(i+1)+") "+v.label} data={opt} isValidator={validator} isRule={v.add_infos && v.add_infos.validation?v.add_infos.validation:null} selected={v.add_infos.selected} other={v.add_infos && v.add_infos.other ?v.add_infos.other:false} />
                                                                            </div>
                                                                        </>
                                                                    )
                                                                }
                                                                else if(v.type=="datepicker" || v.type=="datetimepicker" || v.type=="timepicker"){
                                                                    return(
                                                                        <>
                                                                            <div key={v.id+i}>
                                                                                <DatePicker 
                                                                                    type={v.type} 
                                                                                    label={(i+1)+") "+v.label} 
                                                                                    id={v.id} name={v.name} 
                                                                                    isValidator={validator} 
                                                                                    isRule={v.add_infos && v.add_infos.validation?v.add_infos.validation:null} 
                                                                                />
                                                                            </div>
                                                                        </>
                                                                    )
                                                                    
                                                                }
                                                            })
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                        </div>
                                    <button type="submit" class="btn btn-primary btn-lg btn-custom" disabled={isDisabled && !isNotif?true:false}>{isDisabled && !isNotif?"Loading":"Submit"}</button>
                                    </form>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

class Home extends Component{
    constructor(props){
        super(props)
        this.state={
            loading:true,
            loadingAnimation:'.',
            notif:this.getSessionStorage('notif'),
            time_begin: moment().unix()
        }
        this.loadingAnimation()
        this.validator = new SimpleReactValidator();
    }

    isValidator=(key, value, rule)=>{
        return this.validator.message(key, value, rule);
    }

    loadingAnimation=async()=>{
        let a=0;
        let loadAnimation= setInterval(async()=>{
            if(a==3){
                a=0
            }
            let dot='.'
            for(let i=0; i<=a; i++){
                if(i!=0){
                    dot= dot+'.'
                }
            }
            a++
            await this.setState({
                loadingAnimation: dot
            })
            if(!this.state.loading){
                clearInterval(loadAnimation)
            }
        }, 1000)
    }

    componentDidMount=async()=>{
        //request API
        await GetDataQuestionnaire(this.props.match.params.code)
        await Sleep(3000)
        if(this.props.isReqData.status_server==200){
            if(!this.props.isReqData.origin.data || !this.createLocalStorage('token',this.props.isReqData.origin.data.token)){
                this.props.makeResponNull();
            }
        }
        this.setState({
            loading:false,
        })
        sessionStorage.clear();
    }

    loadAnimation_=()=>{
        return this.state.loading?this.state.loadingAnimation:null;
    }

    isRender_= ()=>{
        return !this.state.loading?this.props.isReqData && this.props.isReqData.status_server==200 && this.props.isReqData:null
    }

    getLocalStorage(name){
        try {
            return window.localStorage.getItem(name);
        }
        catch(err) {
            return null;
        }
    }

    createLocalStorage(name, data){
        try {
            window.localStorage.setItem(name, data);
            return true;
        }
        catch(err) {
            return null;
        }
    }

    createSessionStorage(name, data){
        try {
            window.sessionStorage.setItem(name, data);
            return true;
        }
        catch(err) {
            return null;
        }
    }

    getSessionStorage(name){
        try {
            return sessionStorage.getItem(name)
        }
        catch(err) {
            return null;
        }
    }

    isDecodeJwt=async(token)=>{
        let decode=null;
        try{
            decode = await jwt.verify(token, process.env.REACT_APP_SCREET_KEY_JWT);
        }
        catch(err){
            decode= null;
        }
        return decode;
    }

    onSubmit_=async(event)=>{
        event.preventDefault();
        if (this.validator.allValid()) {
            this.setState({
                notif:''
            })
            //data ini sudah pasti kerender duluan
    
            let data= this.isRender_();
            let tmp=[];    
            if(data){
                data.origin.data.models.map((value, index)=>{
                    value.quistions.map((v, i)=>{
                        if(v.type=='checkbox'){
                            let chckbox_=[];
                            event.target[v.name+'[]'].forEach((val, ind)=>{
                                if(val.checked){
                                    //checked
                                    if(val.value=='others'){
                                        chckbox_.push({"value":event.target[v.name+'[others]'].value});
                                    }
                                    else{
                                        chckbox_.push({"value":val.value});
                                    }
                                }
                            });
                            tmp.push({"name":v.name, "value":chckbox_, "type_answer":v.type});
                        }
                        else{
                            if(event.target[v.name]){
                                if(event.target[v.name].value=='others'){
                                    tmp.push({"name":v.name, "value":event.target[v.name+'[others]'].value, "type_answer":v.type})
                                }
                                else{
                                    tmp.push({"name":v.name, "value":event.target[v.name].value, "type_answer":v.type})
                                }
                            }
                        }
                    })
                })
                //ubah dulu ke json baru kirim ke server
                let token = this.getLocalStorage('token');
                let decode = await this.isDecodeJwt(token);
                if(decode && decode.code && decode.uid){
                    let userResponse={
                        uid:decode.uid,
                        code:decode.code,
                        time_begin:this.state.time_begin,
                        time_finish:moment().unix(),
                        userResponse:JSON.stringify(tmp)
                    }
                    await SaveDataQuestionnaire(userResponse);
                    await Sleep(1000)
                    if(this.props.isResponseSaveData.status_server!=200 || (this.props.isResponseSaveData.origin && this.props.isResponseSaveData.origin.code!=1)){
                        this.setState({
                            notif:'Kuisioner kamu gagal disimpan, mohon coba lagi ya...'
                        });
                    }
                    else{
                        this.props.getResponseSave(this.props.isResponseSaveData);
                    }
                }
                else{
                    // reload and show notif data not saved
                    this.createSessionStorage('notif', 'Kuisioner kamu gagal disimpan, mohon coba lagi ya...');
                    //reload
                    window.location.reload();
                }
            }
            else{
                return null;
            }
            sessionStorage.clear();
        } else {
            this.setState({
                notif:'Oups, perbaiki kesalahan dulu...'
            })
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    }

    render(){
        return(
            <div style={{flex:1}}>
                <IsRender 
                    data={{ 
                        loadAnimation:this.loadAnimation_(),
                        isRender:this.isRender_(),
                        isNotif:this.state.notif
                    }}
                    onSubmit={(event)=>{this.onSubmit_(event)}}
                    validator={this.isValidator}
                />
            </div>)
    }
}

function mapStateToProps(state){
    return {
        isReqData: state.isQuestionnaire.req_data,
        isResponseSaveData: state.isQuestionnaire.response_data,
        isLoad: state.loadingBar.loading
    }
}
function mapDispatchToProps(dispatch) {
    return {
        makeResponNull: () => dispatch({ type: 'GET_RESPONSE_INIT', data: null }),
        getResponseSave: (data) => dispatch({ type: 'GET_RESPONSE_INIT', data: data }),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Home);