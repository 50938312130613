import {store} from '../Store'
import {IsApi} from '../IsApi'
import {RedirectLogin, Sleep} from '../IsConfig'
import { hideLoading, showLoading } from 'react-redux-loading-bar'

export const GetDataQuestionnaire=async(code)=>{
  store.dispatch(showLoading())
  let start = new Date();
  let uri= 'questionnaires?code='+code;
  let result= await IsApi('GET',uri);
  if(result.status_response){
      let tmp_data={status_server:result.status_server, type:'response_ok', origin:result.response_origin.data, message:result.response_origin.statusText};
      store.dispatch({ type: 'GET_RESPONSE_INIT', data:tmp_data })
  }
  //error request API
  else{
    let tmp_data;
    if(result.status_server==204){
      tmp_data={status_server:result.status_server, type:'error', origin:null, message:'Ok, but data null'}
    }
    else if(result.status_server==401){
      window.location= RedirectLogin
    }
    else if(result.status_server==403){
      tmp_data={status_server:result.status_server, type:'error', origin:null, message:result.response_origin.data.message}
    }
    else{
      tmp_data={status_server:result.status_server, type:'network_error', origin:null, message:result.response_origin.stack}
    }
    store.dispatch({ type: 'GET_RESPONSE_INIT', data:tmp_data })
    
  }
  let elapsed = new Date() - start;
  if(elapsed>3000){
    store.dispatch(hideLoading())
  }
  else{
    await Sleep(3000)
    store.dispatch(hideLoading())
  }
}


export const SaveDataQuestionnaire=async(data)=>{
  store.dispatch(showLoading())
  let start = new Date();
  let result= await IsApi('POST','questionnaires/save', data);
  if(result.status_response){
    let tmp_data={status_server:result.status_server, type:'response_ok', origin:result.response_origin.data, message:result.response_origin.statusText};
    store.dispatch({ type: 'SAVE_RESPONSE', data:tmp_data })
  }
  //error request API
  else{
    let tmp_data;
    if(result.status_server==204){
      tmp_data={status_server:result.status_server, type:'error', origin:null, message:'Ok, but data null'}
    }
    else if(result.status_server==401){
      window.location= RedirectLogin
    }
    else if(result.status_server==403){
      tmp_data={status_server:result.status_server, type:'error', origin:null, message:result.response_origin.data.message}
    }
    else{
      tmp_data={status_server:result.status_server, type:'network_error', origin:null, message:result.response_origin.stack}
    }
    store.dispatch({ type: 'SAVE_RESPONSE', data:tmp_data })
    
  }
  let elapsed = new Date() - start;
  if(elapsed>3000){
    store.dispatch(hideLoading())
  }
  else{
    await Sleep(3000)
    store.dispatch(hideLoading())
  }
}

