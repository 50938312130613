import React, { useState } from 'react';

export const RadioButton = ({name="", id="", label="", data=[], checked="", other=false, isValidator, isRule, ...props}) => {
    const [isChecked, setIsChecked] = useState(checked);
    const [isCheckedHelper, setSelectedHelper] = useState(checked);
    const [isShow, setIsShow] = useState(false);
    
    return(
        <div class="input-group">
            <label htmlfor={id?id:name}>{label}</label>
            {
            data && data.map((value, index)=>{
                return (<div class="clear-fix" key={(id?id:name)+'-'+index}>
                    <div class="pretty p-default p-round p-thick p-pulse">
                        <input onClick={(event)=>{setIsChecked(event.target.value); setIsShow(false)}} checked={isChecked == value.value?true:false} value={value.value} id={(id?id:name)+'-'+index} name={name} type="radio" {...props} />
                        <div class="state p-primary-o">
                            <label for={(id?id:name)+'-'+index}>{value.desc}</label>
                        </div>
                    </div>
                </div>)
            })
            }
            {
                other && (
                    <div class="clear-fix">
                        <div class="pretty p-default p-round p-thick p-pulse">
                            <input onClick={(event)=>{setIsChecked(event.target.value); setIsShow(true)}} checked={isChecked=="others"||isShow?true:false} id={(id?id:name)+'-others'} value={"others"} name={name} type="radio" {...props} />
                            <div class="state p-primary-o">
                                <label for={(id?id:name)+'-others'}>{other.desc}</label>
                            </div>
                        </div>
                        <div style={{display:(isShow?"block":"none")}}>
                        {
                            other.type=="text" && (
                                <div className="input-group" style={{marginTop:5}}>
                                    <input onChange={(event)=>{setSelectedHelper(event.target.value)}} className="form-control" id={(id?id:name)+'-others'} name={name+"[others]"} type="text" value={isCheckedHelper} {...other.props} />
                                </div>      
                            )
                        }
                        {
                            other.type=="textarea" && (
                                <div className="input-group" style={{marginTop:5}}>
                                    <textarea onChange={(event)=>{setSelectedHelper(event.target.value)}} className="form-control" id={(id?id:name)+'-others'} name={name+"[others]"} {...other.props} >{isCheckedHelper}</textarea>
                                </div>      
                            )
                        }
                        </div>
                    </div>
                )
            }
            {
            isValidator && isRule && (
                <>
                    <span style={{color:'red', float:'inline-end'}}>{isValidator(name, isChecked=="others"?(isCheckedHelper?isCheckedHelper:null):isChecked, isRule)}</span>
                </>
            )   
            }
        </div>
    )
}
